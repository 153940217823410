<template>
  <div class="plan-content">
    <!-- <van-pull-refresh @refresh="getArrangement(1)" v-model="refreshLoading"> -->
      <div v-if="arrangement.length > 0" style="background:#f3f3f9">
        <div
          v-for="(plan, index) in arrangement"
          :key="index"
          class="plan-wrap mb-20"
          :style="{ marginTop: canBack ? '0' : '15px' }"
        >
          <div class="plan-label">
            <div class="mb-5">{{ plan.apply.name }}</div>
            <div>{{ plan.apply.phone }}</div>
          </div>
          <transition  name="plan" appear tag="div" v-if="plan.hotels.length > 0">
            <div class="container hotel">
              <div class="title">
                <div>
                  <span style="color:#5094ff; margin-right:5px; font-size:15px">●</span>酒店安排
                </div>
                <img style="height: 35px" src="@/assets/images/myplan/hotel.png" alt="">
              </div>
              <van-divider style="margin:5px 0 15px 0;" />
              <div class="content">
                <div class="center-vertical">
                  <table class="plan-table" cellspacing="0">
                    <thead>
                      <tr>
                        <td>酒店名称</td>
                        <td>房型</td>
                        <td>房号</td>
                        <td>入住时间</td>
                        <td>离店时间</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in plan.hotels" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.roomType }}</td>
                        <td>{{ item.roomNumber }}</td>
                        <td>{{ item.startTime.substr(0,10) || '' }}</td>
                        <td>{{ item.endTime.substr(0, 10) || '' }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </transition>
          <transition  name="plan" appear tag="div" v-if="plan.vehicles.length > 0">
            <div class="container car">
              <div class="title">
                <div>
                  <span style="color:#ffd02a; margin-right:5px; font-size:15px">●</span>车辆安排
                </div>
                <img src="@/assets/images/myplan/car.png" alt="">
              </div>
              <van-divider style="margin:5px 0 15px 0;" />
              <div class="content">
                <div class="center-vertical">
                  <table class="plan-table" cellspacing="0">
                    <thead>
                      <tr>
                        <td>车号</td>
                        <td>车牌号</td>
                        <td>车辆路线</td>
                        <td>联系人</td>
                        <td>联系人电话</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in plan.vehicles" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.numberPlate }}</td>
                        <td>{{ item.route }}</td>
                        <td>{{ item.contactPerson }}</td>
                        <td>{{ item.contactPhone }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </transition>
          <transition  name="plan" appear tag="div" v-if="plan.seats.length > 0">
            <div class="container seat">
              <div class="title">
                <div>
                  <span style="color:#28cf10; margin-right:5px; font-size:15px">●</span>座位安排
                </div>
                <img src="@/assets/images/myplan/sofa.png" alt="">
              </div>
              <van-divider style="margin:5px 0 15px 0;" />
              <div class="content">
                <div class="center-vertical">
                  <table class="plan-table" cellspacing="0">
                    <thead>
                      <tr>
                        <td>会场名称</td>
                        <td>座位信息</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in plan.seats" :key="index">
                        <td>{{ item.groupName }}</td>
                        <td>{{ item.name }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </transition>
          <transition  name="plan" appear tag="div" v-if="plan.meals.length > 0">
            <div class="container meal">
              <div class="title">
                <div>
                  <span style="color:#ff5000; margin-right:5px; font-size:15px">●</span>用餐安排
                </div>
                <img src="@/assets/images/myplan/eat.png" alt="">
              </div>
              <van-divider style="margin:5px 0 15px 0;"/>
              <div class="content">
                <div class="center-vertical">
                  <table class="plan-table" cellspacing="0">
                    <thead>
                      <tr>
                        <td>名称</td>
                        <td>地点</td>
                        <td>用餐日期</td>
                        <td>开始时间</td>
                        <td>结束时间</td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in plan.meals" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.address }}</td>
                        <td>{{ item.dateInfo }}</td>
                        <td>{{ item.startTime }}</td>
                        <td>{{ item.endTime }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </transition>
          <van-empty description="暂无数据" v-if="!plan.hotels.length > 0 && !plan.vehicles.length > 0 && !plan.seats.length > 0 && !plan.meals.length > 0">
            <van-button round style="color:#666" v-if="canBack" @click="goBack">返回查询</van-button>
          </van-empty>
        </div>
      </div>
      <van-empty description="暂无数据" v-else >
        <van-button round style="color:#666" v-if="canBack" @click="goBack">返回查询</van-button>
      </van-empty>
    <!-- </van-pull-refresh> -->
  </div>
</template>

<script>
export default {
  name: 'planeList',
  props: {
    arrangement: {
      type: Array,
      default: () => {
        return []
      }
    },
    refreshLoading: {
      type: Boolean
    },
    canBack: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    getArrangement(type) {
      this.$emit('getArrangement', type)
    },
    goBack() {
      this.$emit('goBack')
    }
  }
}
</script>

<style scoped lang="less">
.plan-content{
  min-height: 100%;
}
.plan-wrap{
  padding: 15px;
  background: #fff;
  .van-pull-refresh{
    overflow: visible;
  }
  .plan-label{
    font-size: 0.35rem;
    margin-bottom: 0.2rem;
    color: #5e5e5e;
  }
  .container{
    display: flex;
    flex-direction: column;
    padding: 10px;
    padding-bottom: 25px;
    padding-top: 5px;
    background: #fff;
    margin-bottom: 15px;
    border-radius: 8px;
    box-shadow: 0 0 5px 2px rgba(0,0,0,.1);
    .title{
      display: flex;
      justify-content: space-between;
      height: 50px;
      align-items: center;
      font-size: 16px;
      font-weight: bold;
      img{
        height: 37px;
        width: 37px;
      }
    }
  }
  .container:nth-child(1){
    transition-delay: 0;
  }
  .container:nth-child(2){
    transition-delay: .2s;
  }
  .container:nth-child(3){
    transition-delay: .4s;
  }
  .container:nth-child(4){
    transition-delay: .6s;
  }
  .container:nth-child(5){
    transition-delay: .8s;
  }
  .container:nth-child(6){
    transition-delay: 1s;
  }
}
.center-vertical{
  flex-direction: column;
  p{
    color: #aeaeae;
    font-size: 12px;
    margin: 0;
  }
}
.plan-enter{
  transform: translateY(200px);
  opacity: 0;
}
.plan-enter-active{
  transition: all .4s ease;
}
.plan-table{
  width: 100%;
  font-size: 11px;
  border-left: 1px solid #eee;
  border-bottom: 1px solid #eee;
  color: #333;
  td{
    border-right: 1px solid #eee;
    border-top: 1px solid #eee;
    padding: 5px 0;
  }
  thead{
    tr{
      background: #f9f9f9;
      text-align: center;
    }
  }
  tbody{
    tr{
      text-align: center;
      font-size: 10px;
      td{
        height: 0.5rem;
        line-height: 0.5rem;
      }
    }
  }
}
</style>
