<template>
  <div style="width: 100%; margin-top: 0.4rem">
    <div class="ticket-wrap">
      <div class="content">
        <div class="center-vertical" :style="{ fontSize: '16px', background: $store.state.themeInfo.themeColor }">
          <span class="title text-ellipsis">{{ $store.state.miceInfo.miceName }}</span>
          <!-- <span class="share" @click="switchType" v-if="auditStatus">
            分享<van-icon name="share-o" />
          </span> -->
        </div>
        <div class="head-img" v-if="userInfo.faceUrl">
          <img :src="userInfo.faceUrl" alt="" />
        </div>
        <!-- <div style="width:100%; padding:0 15px" key="1">
          <span class="float-left">注册号:</span>
          <span class="float-right" :style="{color: $store.state.themeInfo.themeColor}">{{ userInfo.id }}</span>
        </div> -->
        <div style="width: 100%; padding: 0 15px">
          <span class="float-left">参会人:</span>
          <span class="float-right" :style="{ color: $store.state.themeInfo.themeColor }">{{ userInfo.name }}</span>
        </div>
        <div style="width: 100%; padding: 0 15px">
          <span class="float-left">手机号:</span>
          <span class="float-right" :style="{ color: $store.state.themeInfo.themeColor }">{{ userInfo.phone }}</span>
        </div>
        <div style="width: 100%; padding: 0 15px">
          <span class="float-left">单位:</span>
          <span class="float-right" :style="{ color: $store.state.themeInfo.themeColor }">{{ userInfo.company }}</span>
        </div>
        <div style="width: 100%; padding: 0 15px">
          <span class="float-left">注册时间:</span>
          <span class="float-right" :style="{ color: $store.state.themeInfo.themeColor }">{{
            userInfo.createTime
          }}</span>
        </div>
        <div class="group-wrap" v-if="userInfo.auditStatus === 1">
          <table cellspacing="0">
            <thead>
              <tr>
                <th width="20%">参会会场</th>
                <th width="20%">时间</th>
                <th width="20%">地点</th>
                <!-- <th width="20%">座位</th> -->
                <th width="20%">审核结果</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in groupList" :key="index">
                <td width="20%" v-html="groupNameFilter(item.name)" style="text-align:center;"></td>
                <td width="20%">{{ item.date | groupDateFilter }}</td>
                <td width="20%">{{ item.address }}</td>
                <!-- <td width="20%">{{ item.name === "开幕式|岳麓论坛" ? '3楼观众厅二层' : "" }}</td> -->
                <td width="20%" style="text-align:center;">
                  <span v-if="userInfo.auditStatus === 1 && +item.state === 1"> 准时参会 </span>
                  <span v-else> 
                    <!-- <span v-if="item.name">审核中</span>   -->
                    很抱歉，坐席已满
                    <!-- 审核中 -->
                  </span>
                  <!-- {{ userInfo.auditStatus }} {{item.state}} -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="ticket-content" v-if="auditStatus">
          <div v-if="groupList.length > 0">
            <van-divider style="width: 100%; margin-bottom: 0" dashed>签到二维码</van-divider>
            <!-- <div style="position:relative" @click="refreshCode"> -->
            <div style="position: relative" @click="refreshCode">
              <vue-qr v-if="userInfo.code" :text="userInfo.code" :size="200" />
              <div class="loading-mask" v-if="freshLoading">
                <van-loading type="spinner" />
              </div>
            </div>
            <p style="margin: 0; font-size: 10px; color: #aeaeae; text-align: center">出示二维码，签到更方便</p>
            <p style="margin: 0; font-size: 10px; color: #aeaeae; text-align: center; margin-bottom: 10px">
              二维码一分钟过期，点击二维码刷新
            </p>
          </div>
        </div>
        <span
          style="font-size: 18px; color: #666; height: 100px; line-height: 100px; font-weight: bolder"
          v-else-if="userInfo.auditStatus === 3"
          >报名信息审核中</span
        >
        <div style="font-size: 14px; color: #666; padding: 0 15px" v-else>
          <p style="margin: 5px 0">报名结果：坐席已满</p>
          <p style="margin: 5px 0">很抱歉，您报名的2024年岳麓峰会活动坐席已满，感谢您的参与！</p>
        </div>
        <!-- <div class="question">
          健康问卷填写情况：<span :style="{color: isSubmitQuestion ? '#03045e': '#d62828',textDecoration:isSubmitQuestion? 'underline':'none' }" @click="toQuestionPage">{{ isSubmitQuestion ? "健康申报已填写，点击查看" : "健康申报未填写" }}</span>
          <div class="btn">
            <van-button
            type="primary"
            v-if="!isSubmitQuestion"
            size="mini"
            :style="{ background: $store.state.themeInfo.themeColor, borderColor: $store.state.themeInfo.themeColor }"
            @click="toQuestionPage"
            >
            立即填写健康问卷
          </van-button>
          </div>
        </div> -->
      </div>
      <div></div>
      <healthResultTip :userInfo="userInfo" />
    </div>

    <!-- 防疫须知 -->
    <transition name="slip">
      <div class="dialog-container" v-show="dialogShow">
        <div class="dialog-box">
          <!-- <div class="close" @click="confirm">
            <van-icon :color="timeOk ? $store.state.themeInfo.themeColor : '#999'" name="cross" />
          </div> -->
          <div class="content">
            <div class="dialog-title">2023互联网岳麓峰会防疫须知</div>
            <div class="confirm-wraper">
              <p>为保障您与他人的身体健康，请您熟知以下防疫要求：</p>
              <p>1、如有以下情况的，不得参加本次会议：</p>
              <p>①大会前14 天内有国(境)外或港台旅居史的;</p>
              <p>②大会前10 天内有海南、西藏、新疆(含兵团)、青海、陕西、重庆全域等疫情严重地旅居史的;近7天内有外溢风险较大的地区(江西鹰潭，河南三门峡、信阳，广东深圳南山区、宝安区、盐田区、佛山禅城区、广州越秀区，甘肃庆阳，湖北襄阳，福建泉州晋江，四川阿坝州、南充，黑龙江齐齐哈尔、大庆，安徽安庆，云南昆明盘龙区，辽宁大连沙河口区，内蒙古阿拉善、二连浩特，浙江宁波鄞州区，吉林珲春，广西防城港)旅居史的; 近7天内有高、中风险地区所在县(县级市、区、自治县、旗、自治旗等)旅居史的;</p>
              <p>③有发热、干咳、乏力、咽痛、鼻塞、流涕、结膜炎、肌肉酸痛、味嗅觉减退或丧失、腹泻等新冠肺炎相关可疑症状且不能排除传染病;</p>
              <p>④湖南居民健康码为红码或黄码的;</p>
              <p>⑤正处隔离治疗、隔离医学观察、居家健康监测的;或与新冠病例有时空交集的;</p>
              <p>⑥报到时不能提供48小时内核酸检测阴性证明的(省外常态化防控区旅居史人员无抵长后“三天两检”核酸阴性证明);</p>
              <p>⑦高风险岗位从业人员脱离岗位后，未完成7天集中或居家隔离者;</p>
              <p>⑧工作人员不能提供大会前3天2次核酸阴性证明的(工作人员会议期间须每日1 次核酸检测);</p>
              <p>⑨经防疫部门评估，其他情况不适合参会的。</p>
              <p>2、核酸检测</p>
              <p>会前：所有参会人员及工作人员报到时须做一次核酸检测(当天在“三站一场”已开展了落地核酸检测的不需重复做)。</p>
              <p>
                会期：所有参会嘉宾持48小时内核酸检测阴性证明入长或返长，报到时须做一次核酸检测(当天在“三站一场”已开展了落地核酸检测的不需重复做)，省外来（返）长人员在会议入场时需提供入（返）湘后“三天两检”核酸阴性证明。
              </p>

              <p>组委会也将在峰会定点酒店及会场外每天设置免费核酸采样点为嘉宾提供服务，会议期间必须按照组委会安排每日开展1次核酸，持24小时核酸检测阴性证明方可进入会场。</p>
              <div class="tips">注:核酸检测前48小时不能接种新冠疫苗。</div>
              <p>3、温馨提示</p>
              <p>①如遇健康码、行程码排查有异常的，报当地疫情防控指挥部甄别判断。</p>
              <p>②会议期间请您全程规范佩戴口罩，废弃口罩请勿随意丢弃，需投入废弃口罩专用垃圾桶。</p>
              <p>③会议期间请注意保持1米以上间距，会议结束后注意有序离场切勿扎堆。</p>
              <p>④参会人员和工作人员均实行闭环管理，不得参加非组委会安排的其他聚集性活动和前往人员密集的场所。</p>
              <p>⑤所有来长参会人员途中乘坐公共交通工具须全程规范佩戴好口罩，注意手卫生、保持社交距离等个人防护，乘坐公共交 通工具期间尽量避免因进食等原因出现摘脱口罩的情况。</p>
            </div>
          
          </div>
            <div class="footer">
              <van-button
                class="bth"
                :style="{ color: timeOk ? $store.state.themeInfo.themeColor : '#999' }"
                :disabled="!timeOk"
                @click="confirm"
                >{{ confirmText }}</van-button
              >
            </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vueQr from "vue-qr";
import healthResultTip from "@/components/signForm/components/healthResultTip.vue";
import data from '../../../index/data';
export default {
  name: "myTicket",
  components: { vueQr, healthResultTip },
  filters:{
    groupDateFilter:function(date){
      return date.slice(0,date.lastIndexOf('-')) +'开始'
    }
  },
  props: {
    userInfo: {
      type: Object
    },
    // 是否完成问卷填写
    isSubmitQuestion: {
      type: Boolean
    }
  },
  data() {
    return {
      loading: false,
      dialogShow: false,
      freshLoading: false,
      form: {
        phone: "",
        verifyCode: ""
      },
      confirmText: "我已阅(6s)",
      timeOk: false,
      time: 6,
      groupList: [],
      stateOptions: {
        1: "准时参会",
        2: "审核通过",
        3: "审核不通过"
      }
    };
  },
  watch: {
    userInfo: {
      handler(val) {
        console.log(val);
        if (val.groupInfo) {
          const arr = JSON.parse(val.groupInfo);
          arr.forEach(item => {
            const state = item.state.split("-");
            const index = this.groupArray.findIndex(item => {
              return item.groupLink === state[0];
            });
            this.groupList.push({
              name: this.groupArray[index].name,
              address: this.groupArray[index].address,
              date:
                this.groupArray[index].startDate +
                " " +
                this.groupArray[index].startTime.substr(0, 5) +
                "-" +
                this.groupArray[index].endTime.substr(0, 5),
              state: state[1]
            });

            // // if (state[1] === '1' || val.auditStatus !== 1) {
            // //   const index = this.groupArray.findIndex(item => {
            // //     return item.groupLink === state[0]
            // //   })
            // //   this.groupList.push({
            // //     name: this.groupArray[index].name,
            // //     address: this.groupArray[index].address,
            // //     date: this.groupArray[index].startDate + ' ' + this.groupArray[index].startTime.substr(0, 5) + '-' + this.groupArray[index].endTime.substr(0, 5)
            // //   })
            // }
          });
        }
      },
      immediate: true
    }
  },
  computed: {
    auditStatus() {
      return this.$store.state.miceInfo.isAudit === 1
        ? this.userInfo.auditStatus
          ? this.userInfo.auditStatus === 1
          : false
        : true;
    },
    groupArray() {
      return this.$store.state.groupInfo.groupArray;
    }
  },
  created() {
    this.agreeTimeOut();
  },
  methods: {
    groupNameFilter(name){
      let newName
      if(name.indexOf('  ') >=0){
       newName = name.split('  ').join('<br>')
      }else{
        newName = name.split(' ').join('<br>')
      }
      return newName
    },
    toQuestionPage() {
      this.$router.push(`/questionnaire/default/050a6644946abe7a492c67587fb6357c/0f9991f1f7f446ffbd5c12eaa4636122`);
    },  
    agreeTimeOut() {
      const interval = setInterval(() => {
        if (this.time <= 0) {
          this.confirmText = "我已阅";
          clearInterval(interval);
          this.timeOk = true;
          return;
        }
        this.confirmText = `我已阅(${this.time}s)`;
        this.time--;
      }, 1000);
    },
    confirm() {
      // console.log('confirm')
      if (!this.timeOk) {
        return;
      }
      this.dialogShow = false;
    },
    async refreshCode() {
      this.freshLoading = true;
      let result = await this.$http.postParams(this.$baseURL.index.refreshCode(), {
        id: this.userInfo.id
      });
      this.freshLoading = false;
      this.$emit("changeCode", result.code);
    },
    switchType() {
      this.$emit("switchType", 2, "normal");
    }
  }
};
</script>

<style lang="less" scoped>
.question {
  margin: 0;
  font-size: 14px;
  color: #666;
  border-top: 1px solid #dfdfdf;
  text-align: center;
  width: 100%;
  padding: 10px 10px;
  .btn {
    margin: 10px;
    text-align: center;
  }
}

@themeColor: var(--themeColor);
.dialog-container {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 2015;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  // overflow-y: scroll;
  // padding: 50px 0;
  .dialog-box {
    position: relative;
    width: 93%;
    height: 80vh;
    // overflow: scroll;
    background: #fff;
    border-radius: 8px;
    overflow: hidden;
    // margin-top: 100px;
    .close {
      position: fixed;
      right: 5vw;
      top: 10vh;
    }
    .content {
      height: 100%;
      padding-bottom: 60px;
      overflow-y: auto;
    }
    .dialog-title {
      width: 100%;
      padding: 25px 0 5px;
      text-align: center;
      font-size: 15px;
      color: #333;
    }
  }

  .confirm-wraper {
    padding: 0 13px 0;
    font-size: 12px;
    // max-height: 300px;
    // overflow-y: auto;
    .color-text {
      color: @themeColor;
    }
    .index {
      width: 9px;
      display: inline-block;
    }
    p {
      margin: 0;
      color: #666;
      &:nth-of-type(1) {
        // text-indent: 15px;
        font-size: 14px;
        margin: 15px 0 10px;
        color: @themeColor;
      }
      span {
      }
      & {
        margin: 2px 0;
      }
    }
  }

  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    // padding: 5px 0;
    margin-top: 10px;
    background: #f4f5ff;
    /deep/.van-button--default {
      border: none;
      width: 100%;
      // border-top: 1px solid @themeColor;
      background: #f4f5ff;
    }
  }
}

.ticket-wrap {
  margin-bottom: 10px;
  padding: 0 20px;
  .content {
    // box-shadow: 0 0 10px 3px #00000015;
    background: #fff;
    // border-radius: 8px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    // padding-bottom: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .center-vertical {
      position: relative;
      color: #fff;
      height: 60px;
      font-size: 12px;
      width: 100%;
      .title {
        display: inline-block;
        max-width: 65%;
        text-align: center;
      }
      .share {
        position: absolute;
        right: 15px;
      }
    }
    .float-left,
    .float-right {
      height: 25px;
      line-height: 25px;
      font-size: 13px;
      color: #666;
    }
    .float-right {
      color: #5094ff;
    }
    .loading-mask {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      background: rgba(255, 255, 255, 0.9);
      &:nth-of-type(2) {
        background: rgba(255, 255, 255, 0.98);
        font-size: 14px;
      }
    }
    .head-img {
      padding: 15px;
      img {
        height: 100px;
        width: 100px;
        // border-radius: 50%;
        overflow: hidden;
        object-fit: cover;
        border-radius: 5px;
      }
    }
    .group-wrap {
      width: 100%;
      padding: 10px;
      table {
        // border-top: 1px solid;
        border-color: #dfdfdf;
        font-size: 12px;
        width: 100%;
        td,
        th {
          padding: 5px 2px;
          border-bottom: 1px solid #dfdfdf;
        }
        td {
          color: #999;
          font-size: 11px;
        }
        th {
          color: #666;
          background: rgb(243, 243, 243);
        }
      }
    }
  }
}
// .ticket-wrap{
//   width: 100%;
//   text-align: center;
// }
.v-enter {
  transform: translateX(50px);
  opacity: 0;
}
.v-enter-active {
  transform: translateX(-50px);
  opacity: 1;
}
</style>
