<template>
  <div
    v-if="userInfo && (userInfo.healthCode || userInfo.healthTraffic)"
    class="tip-container"
  >
    <!-- 存在未识别情况 -->
    <template v-if="userInfo.isDoneHc">
      <div v-if="healthCodeInfo" class="healthcode-wrapper" :style="{color: wordColor}">
        <div>{{healthCodeInfo.healthCodeStatus}}</div>
        <div v-if="healthCodeInfo.healthCodeStatus.indexOf('黄')!==-1" class="tip">请立即隔离</div>
        <div v-if="healthCodeInfo.healthCodeStatus.indexOf('红')!==-1" class="tip">
          <p>请立即与防疫人员联系</p>
          <p>避免人群接触</p>
        </div>
        <div class="refresh-wrapper" @click="handleCodeShowT()">
          <img class="icon-refresh" src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202111181707305639143.png" />
          手动更新
        </div>
      </div>
      <div class="condition-list">
        <div class="condition-item">
          <div class="item-head">
            <img :src="require('@/assets/images/ylfh/icon_condition.png')" alt="">
            <span>入场条件:</span>
          </div>
          <div class="item-content">
            <div>健康码绿码</div>
            <div>48小时核酸阴性</div>
          </div>
        </div>
        <div class="condition-item">
          <div class="item-head">
            <img :src="require('@/assets/images/ylfh/icon_condition.png')" alt="">
            <span>核酸有效时间:</span>
          </div>
          <div class="item-content">

            <template v-if="userInfo.nadTime">
            <!-- <span :style="{color: hour >= 48 ? '#F0A108' : ''}">{{hour}}</span> -->
             
              <div class="hour" v-if="hour>0">
                <span>{{hour}}</span>
                <span>小时</span>
              </div>
              <div class="hour" v-else-if="hour===0 && second >= 0">
                <template v-if="minute>0">
                  <span>{{minute}}</span>
                  <span>分钟</span>
                </template>
                 <template v-if="minute===0">
                  <span>{{second}}</span>
                  <span>秒</span>
                </template>
              </div>
              <div v-else class="mb-10">无48小时内核酸</div>
              <div class="date">
                <!-- <div>当前时间</div> -->
                <div>{{nowTime}}</div>
              </div>
            </template>
            <div v-else>无核酸检测结果</div>
          </div>
        </div>
      </div>
    </template>
    <div v-else style="text-align: center;font-size: 20px;padding: 20px 0;">待确认</div>

    <van-dialog
      v-model="codeTipShowT"
      :showConfirmButton="false"
      :closeOnClickOverlay="true"
      class="dialog"
    >
      <div class="tip-titleT">健康码详细情况</div>
      <div v-if="userInfo.hcMsg === '待确认'" class="dqr">
        系统繁忙，等稍后再试。
      </div>
      <div class="exampleT">
        <div class="pd">
          <span class="determine">防疫要求判定:</span>
          <template v-if="this.$store.state.miceInfo.isAudit">
            <span>{{ userInfo.hcMsg }}</span>
            <!-- <span v-if="userInfo.hcIsOk === true">通行</span>
            <span v-else>
              <span v-if="userInfo.hcMsg === '待确认'"></span>
              <span v-else>禁止通行</span>
            </span> -->
          </template>
          <template v-else>
            <img
              class="status-img"
              style="width: 100px"
              v-if="userInfo.hcIsOk === true"
              src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202111171622329127727.png"
              alt=""
            />
            <template v-else>
              <img
                class="status-img"
                style="width: 110px"
                v-if="userInfo.hcMsg === '待确认'"
                src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202111171623339568210.png"
                alt=""
              />
              <img
                v-else
                class="status-img"
                src="https://sn202108-1305501521.cos.ap-shanghai.myqcloud.com/202112130933390021060.png"
              />
            </template>
          </template>
        </div>
        <template v-if="userInfo.healthCodeState">
          <span class="AI">
            健康码AI识别结果:{{
              userInfo.healthCodeState ? "正常" : userInfo.healthCodeWarning
            }}
            <div>(仅作参考)</div>
          </span>
        </template>
        <template v-if="userInfo.healthTrafficState">
          <span class="AI mar">
            行程码AI识别结果:{{
              userInfo.healthTrafficState
                ? "正常"
                : userInfo.healthTrafficWarning
            }}
            <div>(仅作参考)</div>
          </span>
          <!-- <p>{{item.name}}状态：{{userInfo.healthTrafficState ? '正常' : '不正常'}}</p>
                    <p>{{userInfo.healthTrafficWarning}}</p> -->
        </template>
        <div class="mar">
          核酸检测状态:
          <span v-if="userInfo.hcMsg === '待确认'">待确认</span>
          <span v-else>{{ userInfo.nadState }}</span>
        </div>
        <div class="mar">
          核酸检测时间:
          <span v-if="userInfo.hcMsg === '待确认'">待确认</span>
          <span v-else>{{ userInfo.nadTime }}</span>
        </div>
        <div class="mar">
          新冠疫苗状态:
          <span v-if="userInfo.hcMsg === '待确认'">待确认</span>
          <span v-else>{{ userInfo.vaccineState }}</span>
        </div>
        <div class="mar">
          个人信息更新时间:
          {{ userInfo.time }}
        </div>
        <div class="btnT">
          <van-button
            round
            class="tip-btn"
            size="small"  
            @click="updateUserInfo"
            native-type="button"
            :loading="refreshLoading"
            >手动更新</van-button
          >
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
export default {
  props: {
    userInfo: {
      type: Object,
    },
    auditStatus: {
      type: Boolean,
    },
  },
  data() {
    return {
      refreshLoading: false,
      timer: "",
      value: 1,
      num: "",
      codeTipShowT: false, // 健康码示例
      interval: null,
      nadtimer: null, // 核酸过去小时
      hour: null,
      nowTime: null, // 当前时间
      minute: null, // 剩余分钟
      second: null // 剩余秒
    };
  },
  computed: {
    hsTime() {
      return '24小时'
    },
    auditReason() {
      if (this.userInfo && this.userInfo.auditReason) {
        console.log(this.userInfo.auditReason)
        return JSON.parse(this.userInfo.auditReason)
      } else {
        return []
      }
    },
    // 健康码信息
    healthCodeInfo() {
      if(this.userInfo && this.userInfo.healthCodeInfo) {
        return JSON.parse(this.userInfo.healthCodeInfo)
      } else {
        return ''
      }
    },
    wordColor() {
      if (this.healthCodeInfo) {
        if (this.healthCodeInfo.healthCodeStatus === '绿码') {
          return '#03900D'
        } else if (this.healthCodeInfo.healthCodeStatus.indexOf('黄') !== -1) {
          return '#F0A108'
        } else if (this.healthCodeInfo.healthCodeStatus.indexOf('红') !== -1) {
          return '#FE0101'
        } else {
          return ''
        }
      } else {
        return ''
      }
    }
  },
  mounted() {
    console.log(this.userInfo),
      (this.num = this.$store.state.miceInfo.autoUpdate || 30);
    if (!this.$store.state.miceInfo.isAudit) {
      this.setTime();
    }
    this.diffNadHour()
  },
  destroyed() {
    console.log('destroyed')
    clearInterval(this.nadtimer)
    this.nadtimer = null
  },
  methods: {
    updateUserInfo() {
      this.refreshLoading = true;
      this.$parent.$parent.updateUserInfo().then(() => {
        this.refreshLoading = false;
      });
    },

    setTime() {
      this.interval = setInterval(() => {
        if (this.num === 0) {
          clearInterval(this.interval);
          this.codeTipShowT = true;
          this.updateUserInfo();
        }
        this.num--;
        //   console.log(this.num);
      }, 1000);
    },
    handleCodeShowT() {
      this.codeTipShowT = true;
    },
    async updateIsDoneHc() {
      let result = await this.$http.post(this.$baseURL.index.updateIsDoneHc(), {
        miceId: this.$store.state.miceInfo.id,
        miceLink: this.$store.state.miceInfo.miceLink,
        id: this.userInfo.id
      }, 1)
      if (result.code===200) {
        this.updateUserInfo()
      }
    },
    // 计算核酸剩余有效时间
    diffNadHour() {
      if (this.userInfo.nadTime) {
        this.nowTime = this.$moment().format('YYYY-MM-DD HH:mm:ss')
        this.hour = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'h')
        this.minute = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'minutes')
        this.second = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'seconds')

        this.timer = setInterval(() => {
          this.hour = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'h')
          this.minute = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'minutes')
          this.second = this.$moment(this.userInfo.nadTime).add(48, 'hours').diff(this.$moment(), 'seconds')
        }, 60000)
        return 
      } else {
        return ''
      }
    }
  },
};
</script>

<style lang="less" scoped>
.tip-container {
  background: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0px 10px 0px;
  font-size: 12px;

  .healthcode-wrapper {
    position: relative;
    padding: 28px 0;
    font-size: 45px;
    // font-weight: bold;
    color: #03900D;
    text-align: center;
    border-bottom: 1px solid #C8C8C8;
    .tip {
      margin-top: 8px;
      font-size: 14px;
      line-height: 1;
      p {
        margin: 0;
      }
    }
    .refresh-wrapper {
      position: absolute;
      right: 10px;
      bottom: 10px;
      font-size: 12px;
      color: #1D98BA;
      .icon-refresh {
        width: 9px;
      }
    }
  }
  .condition-list {
    display: flex;
    font-size: 12px;
    color: #292929;
    .condition-item {
      position: relative;
      padding: 0 0 14px 14px;
      width: 50%;
      &:nth-of-type(1) {
        border-right: 1px solid #C8C8C8;
        .item-content {
          color: #AEADAD;
          div:nth-of-type(1) {
            margin-bottom: 16px;
          }
        }
      }
      &:nth-of-type(2) {
        .item-content {
          color: #212121;
          font-size: 14px;
          text-align: center;
        }
        .hour {
          font-size: 14px;
          span:nth-of-type(1) {
            font-size: 51px;
            // font-weight: bold;
            color: #03900D;
          }
        }
   
      }
      .item-head {
        padding: 14px 0 19px;
        display: flex;
        align-items: center;
        img {
          height: 13px;
          margin-right: 3px;
        }
      }
    }
  }



  .dialog {
    display: flex;
    align-content: center;
    width: 292px;
    padding-bottom: 10px;
    .tip-titleT {
      white-space: nowrap;
      width: 146px;
      height: 20px;
      font-size: 21px;
      font-family: HarmonyOS Sans SC;
      font-weight: 400;
      color: #000000;
      margin: 17px 73px 22px 72px;
    }
    .dqr {
      font-weight: 400;
      color: red;
      text-align: center;
    }
    .exampleT {
      // display: flex;
      // flex-direction: column;
      // align-content: center;
      margin-left: 30px;
      font-size: 14px;
      .AI {
        width: 180px;
        height: 20px;
        font-size: 9px;
        font-family: HarmonyOS Sans SC;
        font-weight: 400;
        color: #5b5b5b;
      }
      .pd {
        display: flex;
        align-items: center;
        .determine {
          width: 94px;
          height: 15px;
          font-weight: 400;
          color: #373838;
        }
      }
      .mar {
        margin-top: 10px;
      }
    }
    .btnT {
      text-align: center;
      margin-right: 30px;
      margin-top: 15px;
      .tip-btn {
        padding: 0 20px;
        text-align: center;
        background: #029fc9;
        color: #fff;
      }
    }
  }

  .status-img {
    width: 70px;
  }
  .text-error {
    color: red;
  }
}
</style>
