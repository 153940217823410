<template>
  <div :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div v-if="containsMiceLink">
      <van-sticky type="primary">
        <head-bar :title="headTitle" @onClickLeft="onClickLeft" :right="false" @onClickRight="onClickRight" />
        <!-- <head-bar v-else :title="'个人中心'" @onClickLeft="onClickLeft" :right="true" :vipRight="true" @onClickRight="clickShare" /> -->
      </van-sticky>
      <template v-if="isSearch">
        <van-form @submit="onSubmit" v-if="!searchSuccess">
          <van-field
            v-model="form.phone"
            required
            label="手机号"
            placeholder="手机号"
            type="digit"
            :error-message="phoneErrorMsg"
            maxlength="11"
            @input="phoneErrorMsg = ''"
            :rules="[{ required: true, message: '请填写正确手机号', pattern: phoneRules, trigger: 'onChange' }]"
          >
            <template #label>
              <span>手</span>
              <span>机</span>
              <span>号</span>
            </template>
          </van-field>
          <van-field
            v-model="form.verifyCode"
            center
            required
            :rules="[{ required: true, message: '请输入验证码', trigger: 'onChange' }]"
            clearable
            label="短信验证码"
            :maxlength="6"
            type="digit"
            placeholder="请输入短信验证码"
          >
            <template #button>
              <van-button
                size="small"
                :style="{
                  background: $store.state.themeInfo.themeColor,
                  borderRadius: '5px',
                  border: 'none',
                  color: '#fff'
                }"
                @click="sendCode"
                :disabled="waitCode"
                native-type="button"
              >
                {{ sendCodeText }}
              </van-button>
            </template>
            <template #label> <span>短</span><span>信</span><span>验</span><span>证</span><span>码</span> </template>
          </van-field>
          <div class="center-vertical" style="padding: 15px">
            <van-button
              :disabled="loading"
              :loading="loading"
              round
              block
              type="info"
              :style="{ background: $store.state.themeInfo.themeColor, border: 'none' }"
              >登录</van-button
            >
          </div>

          <div class="register" @click="registerClick">立即注册</div>
        </van-form>

        <div v-else>
          <div>
            <div v-show="!userInfoShow">
              <!-- <van-tabs v-model="tabActive" @click="tabClick" v-if="vipUserInfo.phone">
                <van-tab v-if="userInfo" title="电子票" />
                <van-tab title="行程" />
                <van-tab title="嘉宾邀请" />
              </van-tabs> -->
              <!-- <van-pull-refresh v-model="refreshLoading" @refresh="changeCode"> -->
              <transition appear name="ticket" tag="div" mode="out-in" v-if="!loading">
                <!-- <keep-alive> -->
                <component
                  v-bind:is="witchComp"
                  :userInfo="userInfo"
                  :canBack="false"
                  :arrangement="arrangement"
                  :refreshLoading="refreshLoading"
                  :wzVipJourneys="wzVipJourneys"
                  :vipUserInfo="vipUserInfo"
                  :shareType="shareType"
                  :isSubmitQuestion="isSubmitQuestion"
                  @changeCode="changeCode"
                  @switchType="switchType"
                  @updataUser="updataUser"
                  @getArrangement="getArrangement"
                  @changeUser="changeUser"
                />
                <!-- </keep-alive> -->
              </transition>
            </div>
            <user-info
              v-if="userInfo && active === 1"
              ref="userInfo"
              :userInfo="userInfo"
              @changeCode="changeCode"
              @switchType="switchType"
              @updataUser="updataUser"
            />
          </div>
          <!-- <div class="auditing-wraper" v-else>
            <img :src="userInfo.faceUrl" v-if="userInfo.faceUrl" alt="">
            <div class="text">
              <div class="fl-left">姓名：</div>
              <div class="fl-right">{{ userInfo.name }}</div>
            </div>
            <van-divider></van-divider>
            <div class="text">
              <div class="fl-left">手机号：</div>
              <div class="fl-right">{{ userInfo.phone }}</div>
            </div>
            <van-divider></van-divider>
            <div class="text">
              <div class="fl-left">报名状态：</div>
              <div class="fl-right">{{ userInfo.auditStatus ? (userInfo.auditStatus === 2 ? '审核中' : '审核中') : '' }}</div>
            </div>
          </div> -->
          <!-- </van-pull-refresh> -->
          <!-- <my-ticket :userInfo="userInfo" @changeCode="changeCode" /> -->
        </div>
      </template>

      <van-skeleton style="padding-top: 15px" v-else title :row="4" />
    </div>
    <no-page v-else />
  </div>
</template>

<script>
import myTicket from "./components/myTicket/index";
import Share from "./components/share/index";
import noPage from "@/components/noPage/noPage";
import headBar from "@/components/headBar/headBar";
import userInfo from "./components/userInfo/index";
import planList from "./components/planeList/index";
import vipJourney from "./components/vipJourney/index";
import { Toast, Dialog } from "vant";
export default {
  name: "user",
  components: { noPage, myTicket, headBar, Share, userInfo, planList, vipJourney },
  data() {
    return {
      containsMiceLink: false,
      refreshLoading: false,
      userInfoShow: false,
      miceLink: "",
      active: 1,
      tabActive: 0,
      phoneRules:
        /^((\+|00)86)?((134\d{4})|((13[0-4|5-9]|14[1|5-9]|15[0-9]|16[2|5|6|7]|17[0-8]|18[0-9]|19[0-2|5-9])\d{8}))$/,
      /****** */
      loading: false, // 查询loading
      interval: null,
      phoneErrorMsg: "",
      waitCode: false,
      sendCodeText: "获取验证码",
      searchSuccess: false, // 查询成功
      userInfo: null, // 参会人信息
      form: {
        phone: "",
        verifyCode: ""
      },
      arrangement: [], // 行程
      wzVipJourneys: [],
      vipUserInfo: {},
      shareType: "normal",
      isSearch: false ,// 是否根据openid查询
      isSubmitQuestion: false
    };
  },
  computed: {
    witchComp() {
      if (this.active === 1) {
        return "myTicket";
      } else if (this.active === 2) {
        return "Share";
      } else if (this.active === 3) {
        if (this.wzVipJourneys.length > 0) {
          return "vipJourney";
        } else {
          return "planList";
        }
      } else {
        return "myTicket";
      }
    },
    auditStatus() {
      return this.$store.state.miceInfo.isAudit === 1
        ? this.userInfo.auditStatus
          ? this.userInfo.auditStatus === 1
          : false
        : true;
    },
    headTitle() {
      if (this.userInfo) {
        if (this.userInfo.type===1) {
          return '工作人员个人中心'
        } else  {
          return '个人中心'
        }
      } else {
        return ''
      }
    }
  },
  methods: {
    // 点击注册触发
    registerClick() {
      Toast('报名已结束，感谢您的关注！')

      // const miceLink = this.$store.state.miceInfo.miceLink;
      // if (this.$store.state.miceInfo.registerDeadline) {
      //   const registerDeadline = this.$store.state.miceInfo.registerDeadline.replaceAll("-", "/");
      //   if (!registerDeadline) {
      //     Toast({
      //       message: "敬请期待",
      //       position: "middle"
      //     });
      //     return;
      //   }
      //   const regTime = new Date(registerDeadline).getTime();
      //   const nowTime = new Date().getTime();
      //   if (nowTime > regTime) {
      //     Toast({
      //       message: "敬请期待",
      //       position: "middle"
      //     });
      //     return;
      //   } else {
      //     if (sessionStorage.getItem('type')) {
      //       this.$router.push(`/signup/${miceLink}?type=${sessionStorage.getItem('type')}`);
      //     } else {
      //       this.$router.push(`/signup/${miceLink}`);
      //     }
      //   }
      // } else {
      //   if (sessionStorage.getItem('type')) {
      //     this.$router.push(`/signup/${miceLink}?type=${sessionStorage.getItem('type')}`);
      //   } else {
      //     this.$router.push(`/signup/${miceLink}`);
      //   }
      // }
    },
    onClickLeft() {
      // if (this.searchSuccess) {
      //   this.searchSuccess = false
      //   this.userInfo = {}
      //   this.vipUserInfo = {}
      //   this.wzVipJourneys = []
      //   this.arrangement = []
      //   this.shareType = 'normal'
      //   this.active = 1
      //   this.tabActive = 0
      //   this.form.phone = ''
      //   this.form.verifyCode = ''
      // } else {
      //   this.$parent.onChange(0)
      // }

      //
      if (this.userInfo && this.userInfo.id && this.$refs["userInfo"].$children[0].showCamera) {
        this.$refs["userInfo"].$children[0].showCamera = false;
      } else {
        this.$parent.onChange(0);
      }
    },
    changeUser(user) {
      this.shareType = "vip";
      this.vipUserInfo.name = user.vipName;
      this.vipUserInfo.phone = this.$utils.handlePhone(user.vipPhone);
    },
    onClickRight() {
      if (this.searchSuccess) {
        this.searchSuccess = false;
        this.userInfo = {};
        this.vipUserInfo = {};
        this.wzVipJourneys = [];
        this.arrangement = [];
        this.shareType = "normal";
        this.active = 1;
        this.tabActive = 0;
        this.form.phone = "";
        this.form.verifyCode = "";
      }
    },
    updataUser(userInfo) {
      this.userInfo = userInfo;
    },
    async getGroupList() {
      if (this.$store.state.groupInfo.groupArray.length === 0) {
        let result = await this.$http.get(
          this.$baseURL.index.getGroupList() + "?miceLink=" + this.$store.state.miceInfo.miceLink,
          1
        );
        this.$store.commit("groupInfo/SET_GROUPARRAY", result.data);
      }
    },
    async onSubmit() {
      this.arrangement = [];
      this.loading = true;
      this.form.miceLink = this.$store.state.miceInfo.miceLink || this.miceLink;
      this.form.openId = this.$store.state.userInfo.openid;
      let result = await this.$http.post(this.$baseURL.index.searchByPhone(), this.form, 1);
      this.loading = false;
      if (result.code === 200) {
        if (result.data === null) {
          Dialog.alert({
            title: "提示",
            message: "未查询到您的报名信息"
          }).then(() => {
            // on close
          });
        } else {
          if (result.data.backstageApply) {
            this.userInfo = result.data.backstageApply;
          }
          if (result.data.wzVipJourneys) {
            this.wzVipJourneys = result.data.wzVipJourneys;
            if (this.wzVipJourneys.length > 0) {
              this.vipUserInfo.name = result.data.wzVipJourneys[0].vipName;
              this.vipUserInfo.phone = this.$utils.handlePhone(result.data.wzVipJourneys[0].vipPhone);
              this.shareType = "vip";
            }
            if (this.userInfo) {
              this.active = 1;
            } else {
              this.active = 3;
            }
          }
          this.arrangement.push({
            apply: result.data.backstageApply,
            hotels: result.data.hotels,
            meals: result.data.meals,
            seats: result.data.seats,
            vehicles: result.data.vehicles
          });
          this.searchSuccess = true;
        }
      } else if (result.code === 405) {
        Dialog.alert({
          title: "提示",
          message: "未查询到您的报名信息"
        }).then(() => {
          // on close
        });
      } else {
        Dialog.alert({
          title: "提示",
          message: result.message
        }).then(() => {
          // on close
        });
      }
    },
    getArrangement() {
      // setTimeout(() => {
      //   this.refreshLoading = false
      // }, 1000)
    },
    async sendCode() {
      // 获取验证码
      if (this.phoneRules.test(this.form.phone)) {
        this.phoneErrorMsg = "";
        this.timeOut();
        await this.$http.post(this.$baseURL.index.sendCode(), {
          phone: this.form.phone
        });
      } else {
        this.phoneErrorMsg = "请填写正确手机号";
      }
    },
    timeOut() {
      // 验证码倒计时
      let count = 60;
      this.waitCode = true;
      this.interval = setInterval(() => {
        this.sendCodeText = count + "秒后重试";
        if (count === 0) {
          clearInterval(this.interval);
          this.sendCodeText = "获取验证码";
          this.waitCode = false;
        }
        count--;
      }, 1000);
    },
    changeCode(code) {
      this.userInfo.code = code;
    },
    switchType(index, type = "") {
      if (type === "normal") {
        this.shareType = "normal";
      } else if (type === "vip") {
        this.shareType = "vip";
      }
      this.active = index;
    },
    tabClick(index) {
      if (index === 0) {
        if (this.userInfo) {
          this.active = 1;
        } else {
          this.active = 3;
        }
        this.getInfoByOpenid();
      } else if (index === 1) {
        if (this.userInfo) {
          this.active = 3;
        } else {
          this.active = 2;
        }
      } else if (index === 2) {
        this.active = 2;
      }
      if (this.active === 2) {
        this.shareType = "vip";
      }
    },
    clickShare() {
      this.active = 2;
    },
    // 通过openId查信息
    async getInfoByOpenid() {
      if (!this.$store.state.userInfo.openid) {
        this.isSearch = true;
        return;
      }
      this.isSearch = false;
      const data = {
        openId: this.$store.state.userInfo.openid,
        miceLink: this.miceLink
      };
      let result = await this.$http.post(this.$baseURL.index.searchByOpenid(), data, 1);
      this.isSearch = true;
      if (result.code === 200) {
        if (result.data === null) {
          Dialog.alert({
            title: "提示",
            message: "未查询到您的报名信息"
          }).then(() => {
            // on close
          });
        } else {
          if (result.data.backstageApply) {
            this.userInfo = result.data.backstageApply;
            this.userInfo.hcIsOk = result.data.hcIsOk;
            this.userInfo.hcMsg = result.data.hcMsg;
          }
          if (result.data.wzVipJourneys) {
            this.wzVipJourneys = result.data.wzVipJourneys;
            if (this.wzVipJourneys.length > 0) {
              this.vipUserInfo.name = result.data.wzVipJourneys[0].vipName;
              this.vipUserInfo.phone = this.$utils.handlePhone(result.data.wzVipJourneys[0].vipPhone);
              this.shareType = "vip";
            }
            if (this.userInfo) {
              this.active = 1;
            } else {
              this.active = 3;
            }
          }
          this.arrangement.push({
            apply: result.data.backstageApply,
            hotels: result.data.hotels,
            meals: result.data.meals,
            seats: result.data.seats,
            vehicles: result.data.vehicles
          });
          this.searchSuccess = true;
          // 媒体不用跳转问卷
          this.isAnswerExist()
        }
      }
    },
    // 手动更新
    async updateUserInfo() {
      let result = await this.$http.get(this.$baseURL.index.getrefreshById() + "/" + this.userInfo.id, 1);
      this.userInfo = result.data.backstageApply;
      this.userInfo.hcIsOk = result.data.hcIsOk;
      this.userInfo.hcMsg = result.data.hcMsg;
      this.userInfo.time = result.time;
      // console.log(result);
    },
    // 是否回答问题
    async isAnswerExist() {
      this.answerResult = false;
      const id = "050a6644946abe7a492c67587fb6357c"; // 问卷id
      let result = await this.$http.get(
        this.$baseURL.questionnaire.answerIsExist() +
          `?questionnaireId=${id}&openId=${this.$store.state.userInfo.openid}`,
        1
      );
      this.isSubmitQuestion = result.data
      // if (result.code === 200) {
      //   if (!result.data) {
      //     this.$router.push(`/questionnaire/default/050a6644946abe7a492c67587fb6357c/0f9991f1f7f446ffbd5c12eaa4636122`);
      //   }
      // }
    }
  },
  activated() {
    if (this.$route.params.miceLink) {
      this.miceLink = this.$route.params.miceLink;
      this.containsMiceLink = true;
      this.getGroupList();
      this.getInfoByOpenid();
    } else {
      this.containsMiceLink = false;
    }
  }
};
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
/deep/.van-field {
  &::before {
    content: "";
  }
}
/deep/.van-field__label {
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
}
/deep/.van-field__control {
  &::-webkit-input-placeholder {
    color: #999;
    font-size: 13px;
  }
}
/deep/.van-field__label {
  position: relative;
  color: #222;
  font-size: 13px;
  font-weight: bold;
  margin-right: 0.8rem;
  width: 70px;
  display: flex;
  justify-content: space-between;
}
.auditing-wraper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 15px;
  font-size: 14px;
  color: #666;
  background: #fff;
  /deep/.van-divider {
    width: 100%;
    margin: 5px 0;
  }
  img {
    border-radius: 5px;
    object-fit: cover;
    height: 100px;
    width: 100px;
  }
  .text {
    width: 100%;
    padding: 5px 0;
    .fl-left {
      float: left;
    }
    .fl-right {
      float: right;
      color: @themeColor;
    }
  }
  .status {
    height: 30px;
    line-height: 30px;
  }
}
/deep/ .van-nav-bar__text,
/deep/ .van-nav-bar__arrow,
/deep/ .van-nav-bar__title {
  color: #fff;
}
/deep/.van-tabs__line {
  background: @themeColor;
}
/deep/ .van-pull-refresh {
  overflow: visible;
}
.van-tabs {
  z-index: 1;
}
.van-sticky {
  z-index: 2001;
}
.ticket-enter,
.ticket-leave-to {
  transform: translateY(50px);
  opacity: 0;
}
.ticket-enter-active,
.ticket-leave-active {
  transition: all 0.3s ease;
}

.register {
  height: 15px;
  font-size: 16px;
  font-family: SimHei;
  font-weight: 400;
  text-decoration: underline;
  color: #1f1f20;
  text-align: right;
  margin-right: 14px;
}
</style>
