<template>
  <div :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <div class="trip-body">
      <transition name="list" appear>
        <div class="user-list" v-if="arrangement.length > 1 && !peopleShow">
          <div class="user-item" v-for="(user, index) in arrangement" :key="index" @click="handleShow(user)">
            <van-icon class="label" name="bookmark" />
            <div class="op-back"></div>
            <div class="content">
              <div class="name">{{ user.vipName }}</div>
              <div class="back">
                <van-icon name="down" style="transform:rotate(-90deg)" />
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <transition name="trip" appear>
      <journey-list v-if="peopleShow || (arrangement.length > 0 && arrangement.length === 1)" @back="back" :content="userContent" />
    </transition>
  </div>
</template>

<script>
import journeyList from './components/journeyList'
export default {
  name: 'vipJourney',
  components: {
    journeyList
  },
  props: {
    wzVipJourneys: {
      type: Array,
      default() {
        return []
      }
    }
  },
  watch: {
    wzVipJourneys: {
      handler(val) {
        if (val) {
          this.arrangement = val
          this.userContent = this.arrangement[0]
        }
      },
      immediate: true
    }
  },
  data() {
    return {
      arrangement: [],
      userContent: {},
      peopleShow: false
    }
  },
  methods: {
    handleShow(user) {
      this.userContent = user
      this.$emit('changeUser', user)
      this.peopleShow = true
    },
    back() {
      this.peopleShow = false
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.trip-body{
  // background: #fff;
  overflow-y: auto;
  position: relative;
  // overflow-x: hidden;
  // height: 100%;
  // width: 100%;
  .user-list{
    padding: 15px;
    width: 100%;
    // position: absolute;
    left: 0;
    top: 0;
    .user-item{
      position: relative;
      margin-bottom: 15px;
      padding: 8px;
      height: 90px;
      box-shadow: 0 1px 3px 0 rgba(0,0,23,.08);
      border-radius: 5px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      .label{
        position: absolute;
        left: 25px;
        top: 2px;
        z-index: 2;
        color: @themeColor
      }
      .op-back{
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: @themeColor;
        opacity: .1;
        z-index: 0;
      }
      .content{
        position: absolute;
        height: calc(100% - 16px);
        width: calc(100% - 16px);
        background: #fff;
        box-shadow: 0 1px 3px 0 rgba(0,0,23,.08);
        z-index: 1;
        display: flex;
        border-radius: 5px;
        color: @themeColor;
        align-items: center;
        .name{
          font-size: 17px;
          padding-left: 40px;
          flex: 4;
        }
        .back{
          margin-right: 30px;
          font-size: 15px;
          height: 35PX;
          width: 35PX;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 3px solid @themeColor;
          border-radius: 50%;
        }
      }
    }
  }
}
.list-enter-to,
.list-leave-to,
.trip-enter-to,
.trip-leave-to{
  transition: all .3s ease;
}
.list-enter,
.list-leave-to{
  opacity: 0;
  position: absolute;
  transform: translateX(-100%);
}
.list-enter-to,
.list-leave{
  position: absolute;
}
.trip-enter,
.trip-leave-to{
  opacity: 0;
  transform: translateX(100%);
}
</style>