<template>
  <div>
    <sign-form :isEdit="true" @registerChange="handleChange" :data="userInfo" />
  </div>
</template>

<script>
import signForm from '@/components/signForm/index'
import { Toast } from 'vant'
export default {
  name: 'userInfo',
  props: {
    userInfo: {
      type: Object
    }
  },
  components: { signForm },
  methods: {
    handleChange(result) {
      Toast.success('保存成功')
      let userInfo = {}
       if (result.backstageApply) {
          userInfo = result.backstageApply
          userInfo.hcIsOk = result.hcIsOk
          userInfo.hcMsg = result.hcMsg
        }
      this.$emit('updataUser', userInfo)
    }
  }
}
</script>

<style scoped lang="less">
</style>
