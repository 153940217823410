<template>
  <div class="share-container" :style="{ '--themeColor': $store.state.themeInfo.themeColor }">
    <!-- <div class="back" @click="switchType">
      <van-icon name="arrow-left" />
      {{ wzVipJourneys.length === 0 ? '电子票' : '行程' }}
    </div> -->
    <div class="share-wrapper" v-if="!groupChooseOk">
      <div id="poster" v-if="!success">
        <div calss="head-banner" @click="test">
          <img style="height:4.5rem; width:100%" class="head-img" :src="url" alt="">
        </div>
        <!-- <img style="height:4.5rem; width:100%" class="head-img" :src="url" alt=""> -->
        <div class="info-box">
          <p class="ft-b ft-l mb-10">{{ shareType === 'normal' ? userInfo.name : vipUserInfo.name }}</p>
          <p class="ft-s mb-0">
            邀请你参加2023互联网岳麓峰会<br>
            <span v-for="(item, index) in choosedLive" :key="item.name">{{item.name}}分会场
              <template v-if="(index+1) !== choosedLive.length">、</template><br>
            </span>
          </p>
        </div>
        <div class="info-box">
          <p class="ft-l ft-b">{{ mice.miceName }}</p>
        </div>
        <div class="info-box">
          <p class="mb-0">时间：2023年8月31日-9月1日</p>
          <!-- <p class="mb-0">地点：{{ JSON.parse(mice.address).place }}</p> -->
        </div>
        <div class="center-vertical">
          <vue-qr :text="shareUrl" :size="150" />
        </div>
        <div class="info-box">
          <p class="tip-info">长按嘉宾邀请图片，发送微信好友参加峰会报名</p>
        </div>
      </div>
      <img class="poster" :src="shareImg" alt="" v-else>
      <div class="mask" v-if="!success">
        <van-loading />
      </div>
      <div class="mask-enable" @click.stop="" v-if="!success"></div>
    </div>
    <!-- <van-action-sheet
      v-model="groupChooseOk"
      :actions="actions"
      title="选择分享会场"
      cancel-text="取消"
      close-on-click-action
      @select="selectGroup"
      @cancel="cancel"
    /> -->
    <van-action-sheet
      v-model="groupChooseOk"
      cancel-text="确定"
      close-on-click-action
      v-if="actions.length > 0"
    >
      <van-cell-group>
        <van-cell
          v-for="(item, index) in actions"
          :key="index"
          :title="item.name"
          :label="item.subname"
          @click="groupSelect(index, item)"
          :clickable="!item.disabled"
          :title-style="{ color: item.disabled ? '#cecece' : '', tranistion: '.3s' }"
          :label-class="{ cellMask: item.disabled }"
        >
          <template #right-icon>
            <van-checkbox v-model="item.checked" :disabled="item.disabled" ref="groupCheckBox"></van-checkbox>
          </template>
        </van-cell>
      </van-cell-group>
    </van-action-sheet>
  </div>
</template>

<script>
import vueQr from 'vue-qr'
import html2canvas from 'html2canvas'
export default {
  name: 'share',
  props: {
    userInfo: {
      type: Object
    },
    vipUserInfo: {
      type: Object
    },
    shareType: {
      type: String,
      default() {
        return 'normal'
      }
    },
    wzVipJourneys: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: { vueQr },
  data() {
    return {
      shareImg: '',
      success: false,
      url: '',
      groupList: [],
      groupChooseOk: true,
      actions: [],
      choosed: [],
      choosedLive: [],
      groupShow: false
    }
  },
  computed: {
    mice() {
      return this.$store.state.miceInfo
    },
    sharer() {
      return this.shareType === 'normal' ? this.$utils.encodePhone(this.userInfo.phone) : this.$utils.encodePhone(this.vipUserInfo.phone)
    },
    shareUrl() {
      return `${window.location.href.replace(/user/, 'signup')}/${this.sharer}ANDLINK${this.group}ANDLINK${this.shareType}`
      // return `https://zsi.sensemice.cn/signup/0f9991f1f7f446ffbd5c12eaa4636122/${this.sharer}ANDLINK${this.group}`
    },
    group() {
      return this.$utils.encodeGroup(JSON.stringify(this.choosed))
    },
    selectedGroup() {
      let group = []
      this.actions.forEach(item => {
        if (item.checked) {
          group.push(item.name)
        }
      })
      // return group.join(',')
      return group
    },
  },
  watch: {
    groupChooseOk(val) {
      if(!val) {
        if (this.actions.length > 0) {
          this.actions.forEach(item => {
            if (item.checked) { 
              this.choosed.push(item.groupLink)
              this.choosedLive.push(item)
            }
          })
          this.startDraw()
        }
      }
    }
  },
  mounted() {
    this.$store.state.groupInfo.groupArray.forEach((item) => {
      if (item.name !== '制造新高地 云创新未来') {
        this.actions.push({
          name: item.name,
          groupLink: item.groupLink,
          startDate: item.startDate,
          startTime: item.startTime,
          endTime: item.endTime,
          endDate: item.endDate,
          checked: false,
          disabled: false
        })
      }
    })
  },
  methods: {
    test() {
      console.log(this.$store.state);
    },
    startDraw() {
      this.$http.get(this.$baseURL.index.getBase64() + '?url=' + this.$store.state.miceInfo.posters).then(res => {
        this.url = res
        document.querySelector('.head-img').onload = () => {
          this.createSharePost()
        }
      })
    },
    // selectGroup(item) {
    //   this.choosed = [item.groupLink]
    //   this.startDraw()
    // },
    cancel() {
      this.choosed = []
      this.groupChooseOk = false
      this.startDraw()
    },
    switchType() {
      if (this.wzVipJourneys.length === 0) {
        this.$emit('switchType', 1)
      } else {
        this.$emit('switchType', 3)
      }
    },
    createSharePost() {
      html2canvas(document.querySelector('#poster'), { logging: false }).then(canvas => {
        this.shareImg = canvas.toDataURL()
        this.success = true
      })
    },
    groupSelect(index, child) { // 判断分会场时间冲突
      if (child.disabled) {
        return
      }
      this.$refs.groupCheckBox[index].toggle()
      this.actions[index].checked = !this.actions[index].checked
      this.actions.forEach((item, posi) => {
        if (posi !== index) {
          const is = this.$moment(`${item.startDate} ${item.startTime}`)
          const ie = this.$moment(`${item.endDate} ${item.endTime}`)
          const cs = this.$moment(`${child.startDate} ${child.startTime}`)
          const ce = this.$moment(`${child.endDate} ${child.endTime}`)
          if ((ie > cs && is < ce) || (ce > is && cs < ie)) {
            this.actions[posi].disabled = this.actions[index].checked
            this.$refs.groupCheckBox[posi].toggle(false)
          }
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@themeColor: var(--themeColor);
.share-container{
  min-height: 15.5rem;
  // background: #fff;
  width: 100%;
  padding: 0.8rem;
  .share-wrapper{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
    background: #fff;
    box-shadow: 0 0 8px 2px rgba(0,0,0,.1);
    border-radius: 8px;
    overflow: hidden;
    .poster{
      height: 100%;
      width: 100%;
    }
    .head-banner{
      height: 4.5rem;
      .head-img{
        height: 100%;
        width: 100%;
      }
    }
    .info-box{
      font-size: 13px;
      // padding: 15px 0;
      .ft-l{
        font-size: 15px;
      }
      .ft-s{
        font-size: 11px;
      }
      .ft-b{
        font-weight: bold;
      }
      .mb-0{
        margin: 0;
      }
      p{
        padding: 0 15px;
        text-align: center;
      }
      .tip-info{
        margin-top: -5px;
        margin-bottom: 25px;
        font-weight: bolder;
        color: @themeColor;
      }
    }
  }
  .back{
    margin-bottom: 15px;
    color: #8e8e8e;
    font-size: 13px;
    display: flex;
    align-items: center;
  }
}
.mask{
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #fff;
  z-index: 5;
}
.mask-enable{
  position: fixed;
  height: 100vh;
  width: 100vw;
  z-index: 2005;
  left: 0;
  top: 0;
}
</style>
